import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Container, Row, Col, Carousel, Image } from "react-bootstrap"

import ContactForm from "../components/ContactForm"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import { config } from '@fortawesome/fontawesome-svg-core';
const PageTemplate = ({ data }) => {
  function renderLatAndLon() {
    const kartenausschnitt = data.wpgraphql.offer.acf_offer.kartenausschnitt
    let latitude
    let longitude
    if (kartenausschnitt) {
      latitude = kartenausschnitt.latitude
      longitude = kartenausschnitt.longitude
    }
    const googleMapImage = (
      <img
        className="map-Image"
        src={`https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&size=600x300&zoom=10&scale=2&maptype=roadmap&markers=color:red%7C%7C${latitude},${longitude}&key=AIzaSyDyMunfv8bOKgvt1yjAoQtvyVsKnRnBvqY`}
      />
    )
    return (
      <>
        <Container className="responsive-container">
          <Row className="map-Image">
            <Col>
              {kartenausschnitt && latitude && longitude && googleMapImage}
            </Col>
          </Row>
        </Container>
      </>
    )
  }

  function LatAndLonIsSet() {
    const kartenausschnitt = data.wpgraphql.offer.acf_offer.kartenausschnitt
    if (kartenausschnitt) {
      if (
        kartenausschnitt.latitude&&
        kartenausschnitt.longitude
      ) {
        return true
      }
    }
    return false
  }

  function generateHeadline(art) {
    switch (art){
      case "Vermieten": return "Mietpreis";
      case "Verkaufen": return "Kaufpreis";
      case "Verpachten": return "Pachtpreis";

      default: return "Kaufpreis";

    }
  }

  function generateSpace(art){
    switch (art){
      case "Vermieten": return "Mietfläche";
      case "Verkaufen": return "Größe";
      case "Verpachten": return "Pachtfläche";
    }
  }

  return (
    <Layout style={{paddingBottom:"50px"}}>
       <Container className="listing-container mt-5">
      
        <Carousel>
          {data.wpgraphql.offer.acf_offer.gallerie &&
            data.wpgraphql.offer.acf_offer.gallerie.map(file => (
              file.imageFile &&
              <Carousel.Item>
                <Img
                  className="d-block w-100"
                  style={{ height: "600px" }}
                  sizes={file.imageFile.childImageSharp.sizes}
                />
              </Carousel.Item>
            ))}
        </Carousel>
        <Row>
          <Col className="mt-5" xs={12}>
          <h1 dangerouslySetInnerHTML={{ __html:data.wpgraphql.offer.title }}/>
       
          </Col>
        </Row>
        <p>{data.wpgraphql.offer.acf_offer.beschreibung}</p>
        <h3>Eckdaten</h3>
              
      
        <Row className="mb-3">
        
          <Col xs = {4}>{generateHeadline(data.wpgraphql.offer.acf_offer.art)}</Col>
        
         

          <Col xs={4}>{generateSpace(data.wpgraphql.offer.acf_offer.art)}</Col>

          <Col xs={4}>Verfügbarkeit</Col>

          <Col xs={4} className="detailProp">
          {data.wpgraphql.offer.acf_offer.costcustomtext}
                {data.wpgraphql.offer.acf_offer.cost &&
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits:0,
                  maximumFractionDigits:0,
                  }).format(data.wpgraphql.offer.acf_offer.cost)}{" "}
          </Col>
 
          <Col xs={4} className="detailProp">
            {data.wpgraphql.offer.acf_offer.flaeche}{" "}
            {data.wpgraphql.offer.acf_offer.flaecheTyp}
          </Col>

          <Col xs={4} className="detailProp">            {data.wpgraphql.offer.acf_offer.status}</Col>
        </Row>
        <div ></div>
       
        <Row className="bg-ci-light">
        <Col xs={12} > <h3 className="mt-4">Details</h3></Col>
          {data.wpgraphql.offer.acf_offer.details &&
            data.wpgraphql.offer.acf_offer.details.map((detail,index) =>(
              <>
             { index%6===0 && <div className="w-100"></div>}
              <Col >
              <p>{detail.detailstitle}<br/><span className="detailProp">{detail.detailscontent}</span></p>
              </Col>
              </>
            ))}
         
      
        
        </Row>
       
       
        <h3 className="mt-4">Preise und Kosten</h3>
        <Row>
        <Col xs = {4}>{generateHeadline(data.wpgraphql.offer.acf_offer.art)}</Col>
      

          <Col xs={8} className="detailProp">
          {!data.wpgraphql.offer.acf_offer.cost &&
                  data.wpgraphql.offer.acf_offer.costcustomtext}
                {data.wpgraphql.offer.acf_offer.cost &&
                  new Intl.NumberFormat("de-DE", {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits:0,
                  maximumFractionDigits:0,
                  }).format(data.wpgraphql.offer.acf_offer.cost)}{" "}
          </Col>
        </Row>
        <Row>
          <Col xs={4}>Provision</Col>

          <Col xs={8} className="detailProp">
            {data.wpgraphql.offer.acf_offer.kaeuferprovision}
          </Col>
        </Row>
        <hr className="mt-3" />
     <Row>
     <Col xs={12}>
        <h3 className="mt-4">Objektbeschreibung </h3>
        <div
              dangerouslySetInnerHTML={{
                __html: data.wpgraphql.offer.acf_offer.objektbeschreibung,
              }}
            />
        <hr className="mt-3" />
        </Col>
        </Row>
        <Row>
          <Col className="specialCol">
            <h3 className="mt-4">Lagebeschreibung </h3>
          </Col>
        </Row>
        <Row>
          <Col> <div
              dangerouslySetInnerHTML={{
                __html: data.wpgraphql.offer.acf_offer.lagebeschreibung,
              }}
            /></Col>
        </Row>
        <hr className="mt-3" />
        <Row>
          <Col className="specialCol">
            <h3 className="mt-4">Besichtigungstermin </h3>
            {data.wpgraphql.offer.acf_offer.besichtigungstermin}
          </Col>
        </Row>

        <hr className="mt-3" />
        {data.wpgraphql.offer.acf_offer.sonstiges &&<><Row>
          <Col className="specialCol">
            <h3 className="mt-4">Sonstiges </h3>
            {data.wpgraphql.offer.acf_offer.sonstiges}
          </Col>
        </Row>
        <hr className="mt-3" /></>}
        
        {data.wpgraphql.offer.acf_download.download  &&<><Row>
          <Col className="specialCol">
            <h3 className="mt-4">Downloads </h3>
            {data.wpgraphql.offer.acf_offer.sonstiges}
          </Col>
        {data.wpgraphql.offer.acf_download.download && data.wpgraphql.offer.acf_download.download.map(download=>(
       download.downloadContent && <Col md={12}><a href={download.downloadContent.mediaItemUrl} download><FontAwesomeIcon icon={faDownload}/> {download.downloadContent.title}</a></Col>)
          )}
          </Row>  <hr className="mt-3" /></>}
        <Row>
          <Col className="specialCol">
            <h3 className="mt-4">Immobilienanbieter </h3>
            <div
              dangerouslySetInnerHTML={{
                __html: data.wpgraphql.offer.acf_offer.immobilienanbieter,
              }}
            />
         

            {data.wpgraphql.offer.acf_offer.ansprechpartner}
            {data.wpgraphql.offer.acf_offer.kontakt}
          </Col>
        </Row>
        
      
      {LatAndLonIsSet() && (
        <>
          {renderLatAndLon()}
        </>
      )}
   
      
        <ContactForm />
      </Container>
    </Layout>
  )
}

export default PageTemplate
export const query = graphql`
  query($id: ID!) {
    wpgraphql{
    offer(id:  $id ) {
      id
      content
      acf_download {
        download {
          
          downloadContent {
            staticUrl
            title
            mediaItemId
            mediaItemUrl
            modified
          }
        }
      }
      acf_offer {
        art 
        beitragsbild {
          sourceUrl
          mediaItemId
          mediaItemUrl
          modified
          imageFile {
            childImageSharp {
              sizes(maxWidth: 1100) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        details {
          detailscontent
          detailstitle
        }
        flaecheTyp
        flaeche
        kaeuferprovision
        cost
        costcustomtext
        lagebeschreibung
        status
        gallerie {
          sourceUrl
          mediaItemId
          mediaItemUrl
          modified
          imageFile {
            childImageSharp {
              sizes(maxWidth: 1920) {
                ...GatsbyImageSharpSizes
              }
            }
          }
        }
        objekttyp
        objektbeschreibung
        sonstiges
        besichtigungstermin
        immobilienanbieter
        kartenausschnitt {
          latitude
          longitude
          city
        }
      }
      title
    }}
  }
`
